import { useTranslation } from "react-i18next";
import { FC } from "react";

import Typography from "components/Typography";
import Icon from "components/Icon";

import { Shipping, ShippingMethodType } from "types/shipping";
import { IconName } from "types/icon";

import { PaymentMethodWrapper, ShippingImage } from "./styled";

type RequiredShippingProps = Require<
  Shipping,
  "iconURL" | "description" | "minDuration" | "maxDuration" | "disabled" | "methodType"
>;

type ShippingMethodPropTypes = {
  shippings?: RequiredShippingProps[];
};

const ShippingMethod: FC<ShippingMethodPropTypes> = ({ shippings = [] }) => {
  const { t } = useTranslation();
  const hasShippingMethod = shippings?.length;

  if (!hasShippingMethod) {
    return null;
  }

  return (
    <>
      {shippings.map(
        ({
          iconURL,
          description,
          minDuration,
          maxDuration,
          disabled,
          methodType,
          disabledDuration,
        }: RequiredShippingProps) => {
          const getShippingDays = () => {
            if (minDuration && maxDuration && minDuration === maxDuration) {
              return minDuration;
            }

            return minDuration && maxDuration ? `${minDuration} - ${maxDuration}` : minDuration || maxDuration;
          };

          return (
            !disabled && (
              <PaymentMethodWrapper key={iconURL}>
                {methodType === ShippingMethodType.PICK_UP ? (
                  <>
                    <Icon name={IconName.PICKUP} />
                    <Typography variant="dark-title-5">{t("product.shippingMethod.selfPickup")}</Typography>
                  </>
                ) : (
                  <>
                    <ShippingImage src={iconURL} alt={iconURL} />
                    <Typography variant="dark-title-5">{t(`product.shippingMethod.${description}`)}</Typography>
                    {!disabledDuration && (
                      <Typography variant="dark-body-4">
                        {t("product.shippingMethod.shippingDays", { shippingDays: getShippingDays() })}
                      </Typography>
                    )}
                  </>
                )}
              </PaymentMethodWrapper>
            )
          );
        },
      )}
    </>
  );
};

export default ShippingMethod;
