const product = {
  title: "Product",
  description: "Description",
  conditions: "Conditions",
  digitalContent: {
    availableAt: "Available at",
    days: "days",
    afterPurchased: "after purchased",
    expireAt: "Expire at",
    to: "to",
  },
  productSKUsSelector: {
    select: "Select",
    maximumSelected: "Maximum selected",
  },
  quantity: {
    title: "Quantity",
    remaining: "Only {{stock}} left in stock",
    outOfStock: "Out of stock",
  },
  removeProduct: {
    title: "Are you sure to remove this item?",
  },
  footer: {
    button: {
      remove: "REMOVE",
      addToCart: "ADD TO CART",
      addToCartWithPrice: "ADD TO CART - ฿{{total}}",
      updateCart: "UPDATE CART",
      updateCartWithPrice: "UPDATE CART - ฿{{total}}",
    },
  },
  item: {
    amountBadge: "{{amount}}x",
    price: "฿{{price}}",
    free: "Free",
    outOfStock: "Out of stock",
  },
  promotion: {
    title: "Promotion",
    name: "Promotion name",
    type: "Promotion type",
    promotionType: {
      BUY_X_GET_Y: "Buy...get...free",
      FIXED_AMOUNT_DISCOUNT: "Discount price",
      FIXED_PRICE_DISCOUNT: "Fixed price",
      FREE_GIVEAWAY: "Free gift",
      FREE_LOWEST_PRICE: "Free lowest price item",
      FREE_SHIPPING: "Free shipping",
      PERCENTAGE_DISCOUNT: "Percentage discount price",
    },
    applyBefore: "Apply before",
    mainProduct: "Main product",
    freeProduct: "Free product",
  },
  shippingMethod: {
    header: "Shipping method",
    shippingDays: "{{shippingDays}} Days",
    selfPickup: "Self Pick-up",
    "Thaipost-EMS": "Thaipost-EMS",
    "Thaipost-Register": "Thaipost-Register",
    "Thaipost-MyExpress": "Thaipost-MyExpress",
  },
};

export default product;
