const product = {
  title: "สินค้า",
  description: "รายละเอียด",
  conditions: "เงื่อนไข",
  digitalContent: {
    availableAt: "ใช้ได้",
    days: "วัน",
    afterPurchased: "หลังจากซื้อ",
    expireAt: "ใช้ได้ถึง",
    to: "ถึง",
  },
  productSKUsSelector: {
    select: "เลือก",
    maximumSelected: "เลือกได้สูงสุด",
  },
  quantity: {
    title: "จำนวน",
    remaining: "สินค้าเหลือ {{stock}} ชิ้น",
    outOfStock: "สินค้าหมดชั่วคราว",
  },
  removeProduct: {
    title: "คุณต้องการลบสินค้าใช่หรือไม่?",
  },
  footer: {
    button: {
      remove: "ลบ",
      addToCart: "เพิ่มไปยังตะกร้า",
      addToCartWithPrice: "เพิ่มไปยังตะกร้า - ฿{{total}}",
      updateCart: "อัปเดตตะกร้า",
      updateCartWithPrice: "อัปเดตตะกร้า - ฿{{total}}",
    },
  },
  item: {
    amountBadge: "{{amount}}x",
    price: "฿{{price}}",
    free: "ฟรี",
    outOfStock: "สินค้าหมด",
  },
  promotion: {
    title: "โปรโมชั่น",
    name: "ชื่อโปรโมชั่น",
    type: "ประเภทโปรโมชั่น",
    promotionType: {
      BUY_X_GET_Y: "ซื้อ...แถมฟรี...",
      FIXED_AMOUNT_DISCOUNT: "ลดราคาสินค้า",
      FIXED_PRICE_DISCOUNT: "กำหนดราคาสินค้า",
      FREE_GIVEAWAY: "แถมฟรี",
      FREE_LOWEST_PRICE: "แถมฟรีสินค้าที่ราคาต่ำสุด",
      FREE_SHIPPING: "ส่งฟรี",
      PERCENTAGE_DISCOUNT: "ลดราคาสินค้าเป็นเปอร์เซ็นต์",
    },
    applyBefore: "หมดเขต",
    mainProduct: "สินค้าหลัก",
    freeProduct: "สินค้าแถมฟรี",
  },
  shippingMethod: {
    header: "วิธีการจัดส่ง",
    shippingDays: "{{shippingDays}} วัน",
    selfPickup: "รับสินค้าด้วยตัวเอง",
    "Thaipost-EMS": "ไปรษณีย์ไทย - ส่งด่วนพิเศษ",
    "Thaipost-Register": "ไปรษณีย์ไทย - ส่งลงทะเบียน",
    "Thaipost-MyExpress": "ไปรษณีย์ไทย - ส่งด่วนพิเศษ",
  },
};

export default product;
